/**
 * Login container.
 * @module components/theme/ViewXml/ViewXml
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';
import { Container } from 'semantic-ui-react';
import { Helmet } from '@plone/volto/helpers';

import { settings } from '~/config';

const messages = defineMessages({
  AmministrazioneTrasparente: {
    id: 'AmministrazioneTrasparente',
    defaultMessage: 'Amministrazione trasparente',
  },
});
/**
 * ViewXml class.
 * @class ViewXml
 * @extends Component
 */

class AmministrazioneTrasparente extends Component {
  render() {
    const xmlpath = this.props?.match?.params?.[0];
    let url = xmlpath ? '/pubblicazioneftp/' + xmlpath : null;

    return (
      <div id="page-amministrazione_trasparente" className="ui container">
        <Helmet
          title={this.props.intl.formatMessage(
            messages.AmministrazioneTrasparente,
          )}
        />
        {/*-----Testata-----*/}
        <Container className="PageHeaderWrapper px-3 px-md-4 mb-4 mt-5">
          <div className="row">
            <div className="title-description-wrapper col-lg-8">
              <h1 className="mb-3">
                {this.props.intl.formatMessage(
                  messages.AmministrazioneTrasparente,
                )}
              </h1>

              {/* <p className="description">{content?.description}</p> */}
            </div>
            <div className="col-lg-4 offset-lg-2"></div>
          </div>
        </Container>

        {url && (
          <div className="frame-container">
            <iframe src={url} frameborder="0" scrolling="yes"></iframe>
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withRouter,
  injectIntl,
  connect((state) => ({
    lang: state.intl.locale,
  })),
)(AmministrazioneTrasparente);
