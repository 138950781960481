import React from 'react';
/* import { Icon } from 'design-react-kit/dist/design-react-kit'; */
// eslint-disable-next-line import/no-unresolved
import logo from '~/components/Layout/images/logo.png?width=256';
/*
 * Customization with image
 *
 * If you have a jpg/png, do like this:
 *
 * <figure className="icon">
 *  <img src={logo} alt="" />
 * </figure>
 *
 * Note the icon class.
 */

const Logo = () => (
  <img src={logo} className="icon" width="256" height="70" alt="Logo Asp" />
);

/* <Icon color="" icon="it-pa" padding={false} size="" />; */

export default Logo;
