import React from 'react';
import img from '~/components/Layout/images/search-sections.jpeg';

const Background = (props) => (
  <div
    className="searchSections-background"
    {...props}
    style={{ backgroundImage: `url(${img})` }}
  />
);

export default Background;
